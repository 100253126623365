@import 'variables';

body {
    background-color: $body-background-color;
}

a {
    text-decoration: none;
}

img {
    vertical-align: middle;
}
