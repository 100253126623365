//
//      ORIGINAL FONT FACES
//
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir.eot');
//    src: url('../assets/fonts/vazir/Vazir.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir.ttf') format('truetype');
//    font-weight: normal;
//    font-style: normal;
//}
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir-Bold.eot');
//    src: url('../assets/fonts/vazir/Vazir-Bold.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir-Bold.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir-Bold.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir-Bold.ttf') format('truetype');
//    font-weight: bold;
//    font-style: normal;
//}
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir-Black.eot');
//    src: url('../assets/fonts/vazir/Vazir-Black.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir-Black.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir-Black.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir-Black.ttf') format('truetype');
//    font-weight: 900;
//    font-style: normal;
//}
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir-Medium.eot');
//    src: url('../assets/fonts/vazir/Vazir-Medium.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir-Medium.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir-Medium.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir-Medium.ttf') format('truetype');
//    font-weight: 500;
//    font-style: normal;
//}
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir-Light.eot');
//    src: url('../assets/fonts/vazir/Vazir-Light.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir-Light.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir-Light.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir-Light.ttf') format('truetype');
//    font-weight: 300;
//    font-style: normal;
//}
//@font-face {
//    font-family: Vazir;
//    src: url('../assets/fonts/vazir/Vazir-Thin.eot');
//    src: url('../assets/fonts/vazir/Vazir-Thin.eot?#iefix') format('embedded-opentype'),
//    url('../assets/fonts/vazir/Vazir-Thin.woff2') format('woff2'),
//    url('../assets/fonts/vazir/Vazir-Thin.woff') format('woff'),
//    url('../assets/fonts/vazir/Vazir-Thin.ttf') format('truetype');
//    font-weight: 100;
//    font-style: normal;
//}

@font-face {
    font-family: Vazir;
    src: url('../assets/fonts/vazir/Vazir-Thin.eot');
    src: url('../assets/fonts/vazir/Vazir-Thin.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/vazir/Vazir-Thin.woff2') format('woff2'),
    url('../assets/fonts/vazir/Vazir-Thin.woff') format('woff'),
    url('../assets/fonts/vazir/Vazir-Thin.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: Vazir;
    src: url('../assets/fonts/vazir/Vazir-Medium.eot');
    src: url('../assets/fonts/vazir/Vazir-Medium.eot?#iefix') format('embedded-opentype'),
    url('../assets/fonts/vazir/Vazir-Medium.woff2') format('woff2'),
    url('../assets/fonts/vazir/Vazir-Medium.woff') format('woff'),
    url('../assets/fonts/vazir/Vazir-Medium.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
}

* {
    font-family: Vazir, sans-serif;
    line-height: 1.5;
}

html {
    font-size: 12px;
}

body {
    font-size: 1.4rem;
}

h1 {
    font-size: 2.5rem;
}

h2 {
    font-size: 2.2rem;
}

h3 {
    font-size: 1.8rem;
}

h4 {
    font-size: 1.4rem;
    margin: 1.3rem 0 0.8rem 0;
}

h5 {
    font-size: 1.2rem;
    margin: 1.6rem 0 0.8rem 0;
}

h6 {
    font-size: 1rem;
}
