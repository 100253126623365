@import 'variables';

.header {
    background: $header-background-color;
    padding: 0.25rem 0;
    color: $header-text-color;

    a {
        color: inherit;
    }

    .logo {
        padding: 0.25rem 0;

        img {
            height: 2rem;
            width: 2rem;
        }

        span {
            margin: 0 0.5rem;
            font-weight: bold;
        }
    }

    .link {
        text-align: center;
        display: inline-block;
        height: 100%;
        padding: 0.25rem 0;
        font-size: 90%;

        &:hover, &.active {
            text-decoration: underline;
        }
    }

}