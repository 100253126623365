@import "variables";

.city-list {

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 2rem 0;
            text-align: center;

            .map {
                direction: ltr;
                height: 10rem;
                border: 1px solid $city-list-map-border-color;
                border-radius: 0.5rem;
                margin-bottom: 1rem;
            }
        }
    }

}