// palette:
$color1: #FDF6D8;
$color2: #96C09D;
$color3: #A33220;
$color4: #120F20;
$color5: #9F9C5E;
$color6: #4D2027;


$header-background-color: #312637;
$header-text-color: #fff;

$breadcrumb-background-color: #605266;
$breadcrumb-border-color: #312637;
$breadcrumb-text-color: #fff;

$body-background-color: #312637;
$content-background-color: #fffefa;

$footer-background-color: #627B83;
$footer-text-color: #092e37;
$footer-border-bottom-color: #96AFB8;
$footer-border-top-color: #092e37;

$copyright-text-color: #abadc4;
$copyright-background-color: #092e37;

$form-input-border-color: #AAAABC;
$form-button-border-color: #292A3C;
$form-button-bg-hover-color: #767687;
$form-description-text-color: #605266;

$notification-error-bg-color: #df9f9f;
$notification-error-border-color: #d44a4a;
$notification-success-bg-color: #AAAABC;
$notification-success-border-color: #292A3C;

$city-list-map-border-color: #8f91af;

$consent-background-color: #4D2027;
$consent-text-color: #dfe1f8;

$image-border-color: #ccb35a;
$image-border-color-highlight: #665b2d;

