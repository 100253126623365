@import 'variables';

.consent {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 1rem;
    text-align: center;
    background-color: $consent-background-color;
    color: $consent-text-color;

    a {
        color: $consent-text-color;
        text-decoration: underline;
    }

    button {
        font: inherit;
        border-radius: 0.5rem;
        border: 1px solid $form-button-border-color;
        cursor: pointer;
        padding: 0.5rem 2rem;

        &:hover {
            background-color: $form-button-bg-hover-color;
        }
    }
}