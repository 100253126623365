@import "variables";

.imaged-list {

    ul {
        list-style: none;
        padding: 0;

        li {
            padding: 2rem 0;
            text-align: center;

            img {
                width: 100%;
                border: 1px solid $city-list-map-border-color;
                border-radius: 0.5rem;
                margin-bottom: 1rem;
                background: #ffffff;
            }
        }
    }

}