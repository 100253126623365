@import 'variables';

.breadcrumb {
    background: $breadcrumb-background-color;
    color: $breadcrumb-text-color;
    border-bottom: 1px solid $breadcrumb-border-color;

    a {
        color: inherit;
    }

    ol {
        list-style: none;
        padding: 0;
        font-size: 80%;

        li {
            display: inline-block;

            &:not(:last-child) {
                &::after {
                    content: "»";
                    display: inline-block;
                    padding: 0 0.5rem;
                }
            }

            a:hover {
                text-decoration: underline;
            }
        }
    }
}