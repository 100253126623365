@import 'variables';

.copyright {
    font-size: 80%;
    color: $copyright-text-color;
    padding-top: 1rem;
    padding-bottom: 1.5rem;
    text-align: center;
    background: $copyright-background-color;

    a {
        color: inherit;
        text-decoration: underline;
    }
}