@import 'variables';

form {
    padding: 2rem 0;

    label {
        text-align: left;
        padding: 0.25rem 0;
    }

    select,
    textarea,
    input {
        box-sizing: border-box;
        border: 1px solid $form-input-border-color;
        border-radius: 0.5rem;
        padding: 0.25rem;
        font: inherit;
    }

    button {
        font: inherit;
        border-radius: 0.5rem;
        border: 1px solid $form-button-border-color;
        cursor: pointer;
        padding: 0.5rem 2rem;

        &:hover {
            background-color: $form-button-bg-hover-color;
        }
    }

    .description {
        font-size: 75%;
        color: $form-description-text-color;
    }

    .actions {
        padding: 1rem 0;
        text-align: left;
    }

    .result {
        padding: 1rem 0;

        &.error {
            background-color: $notification-error-bg-color;
            border:1px solid $notification-error-border-color;
            border-radius: 0.5rem;
        }

        &.success {
            background-color: $notification-success-bg-color;
            border:1px solid $notification-success-border-color;
            border-radius: 0.5rem;
        }
    }

}