@import 'variables';

.footer {
    background-color: $footer-background-color;
    color: $footer-text-color;
    border-bottom: 1px solid $footer-border-bottom-color;
    border-top: 1px solid $footer-border-top-color;
    padding-top: 2rem;
    padding-bottom: 5rem;

    a {
        color: inherit;
        text-decoration: underline;
    }
}