@import 'variables';

// TODO this has way to be improved, then extract the variables
#map {
    direction: ltr;
    height: 17rem;
    border-radius: 0.5rem;
}

.photos {
    padding-bottom: 3rem;

    img {
        border-radius: 0.2rem;
        border: 1px solid $image-border-color;
        cursor: pointer;

        &:hover {
            border-color: $image-border-color-highlight;
        }
    }
}

.photo-large {
    position: absolute;
    z-index: 1000;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    background:  rgba(33, 33, 33, .6);;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;

    &.hide {
        display: none;
    }

    img {
        max-height: 85%;
        border-radius: 0.2rem;
    }

    button {
        background: none;
        border: none;
        font-size: 1.5rem;
        text-shadow: 0 0 1px white;
        color: white;
        margin-top: 1rem;
        cursor: pointer;
    }
}

.unmanaged-notice {
    font-size: 65%;
    text-align: left;
    padding-top: 1.5rem;

    a {
        text-decoration: underline;
    }
}